<template>
    <div class="loader" onclick="stop(this)">
        <div class="gif"></div>
        <div class="loadertext">{{ loading_message }}</div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    props: {
        loading_message: String
    },
    mounted() {

    },
    created() {

    }
}
</script>
<style lang="scss" scoped>
.loader {
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    z-index: 500;

    .loadertext {
        margin-top: 20px;
    }

    .gif {
        top: 200px;
        left: 30%;
        border: 16px solid #f3f3f3;
        border-top: 16px solid #3498db;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        animation: spin 2s linear infinite;
        margin-left: 30%;
        margin-top: 200px;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}
</style>