import Web3, { Contract, ContractAbi } from "web3";
import EthContract, { EthTransferData } from "./EthContract";
import Decimal from "decimal.js";
export default class ERC20 extends EthContract {

    /**
     * 获取合约名称
     * @returns 
     */
    public async NameAsync(): Promise<string> {
        return await this.CallAsync('name');
    }
    /**
     * 获取单位
     * @returns 
     */
    public async SymbolAsync(): Promise<string> {
        return await this.CallAsync('symbol');
    }
    /**
     * 获取单位
     * @returns 
     */
    public async DecimalsAsync(): Promise<Decimal> {
        const d = (await this.CallAsync('decimals') as BigInt).toString();
        return new Decimal(d);
    }

    /**
     * 获取总额
     * @returns 
     */
    public async TotalSupplyAsync(): Promise<Decimal> {
        const decimals = await this.DecimalsAsync();
        const totalSupply = (await this.CallAsync<BigInt>('totalSupply')).toString();
        return new Decimal(totalSupply).div(new Decimal(10).pow(decimals));
    }

    /**
     * 获取余额
     * @param address 
     * @returns 
     */
    public async BalanceOf(address: string): Promise<Decimal> {
        const balance = await this.CallAsync<BigInt>('balanceOf', address);
        return new Decimal(balance.toString());
    }

    /**
     * 转账
     * @param from 从谁
     * @param to 到谁
     * @param amount 
     */
    public async TransferAsync(from: string, to: string, amount: number): Promise<EthTransferData> {
        const a = new Decimal(amount).times(new Decimal(10).pow(await this.DecimalsAsync()));
        return await this.SendAsync('transfer', {
            from,
            gasLimit: 100000,
            // gasPrice: 100000 // default gas price in wei, 10 gwei in this case
        }, to, a.toString());
    }
}