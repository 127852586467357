import { ContractAbi } from "web3";

export default class Config {
  private static IS_DEBUG: boolean = false;
  /**
   * API地址
   */
  private static API_URL: string = "http://DESKTOP-SETS4BB:4000/api/";
  /**
   * 钱包相关转账地址
   */
  public static API_Wallet_URL: string = `${Config.API_URL}wallet/`;

  /**
   * 业务功能地址
   */
  public static API_Business_URL: string = `${Config.API_URL}bus/`;

  /**
   * 系统级地址
   */
  public static API_SYS_URL: string = `${Config.API_URL}sys/`;


  //#region 区块链相关
  static fullNodeURL = "https://tron.fullnode.lookbao.net";
  static solidityNodeURL = "https://tron.fullnode.lookbao.net";
  static eventServerURL = "http://tron.fullnode.lookbao.net:16669/";

  /**
   * 系统合约地址
   */
  public static SysContractAddress = this.IS_DEBUG ? '0xDa9d350e72849B452C0d367557BD3677dD07315c' : "0xAf374f156BfA435b545a6999da14Ea27B9a8a173";

  /**
   * erc20的ABI
   */
  public static ERC20ABI: ContractAbi = [
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "tokenName",
          "type": "string"
        },
        {
          "internalType": "string",
          "name": "tokenSymbol",
          "type": "string"
        },
        {
          "internalType": "uint256",
          "name": "decimals",
          "type": "uint256"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "_owner",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "_spender",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_value",
          "type": "uint256"
        }
      ],
      "name": "Approval",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "_from",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "_to",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_value",
          "type": "uint256"
        }
      ],
      "name": "Transfer",
      "type": "event"
    },
    {
      "inputs": [],
      "name": "GetHistoryTotalSupply",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_owner",
          "type": "address"
        }
      ],
      "name": "balanceOf",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "decimals",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "name",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "symbol",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "totalSupply",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "tokenName",
          "type": "string"
        },
        {
          "internalType": "string",
          "name": "tokenSymbol",
          "type": "string"
        }
      ],
      "name": "UpdateTotenName",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_totalSupply",
          "type": "uint256"
        },
        {
          "internalType": "address",
          "name": "_to_reserve_address",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "_reserveTotalSupply",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_pledge_period_time",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_mining_rate",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_max_work_task",
          "type": "uint256"
        }
      ],
      "name": "SetConfigAndStart",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_address",
          "type": "address"
        }
      ],
      "name": "GetUserDataFromAddress",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "_all_balance",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_total_amount_of_withdrawn_coins",
          "type": "uint256"
        },
        {
          "internalType": "string[]",
          "name": "_task_list",
          "type": "string[]"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "guid",
          "type": "string"
        }
      ],
      "name": "GetTaskDataFromGUID",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "_start_time",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_over_time",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_basic_computing_power_accumulation",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_total_amount_of_withdrawn_coins",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_can_total_withdrawal_amount",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "guid",
          "type": "string"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "Destruction",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "start_time_",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "over_time_",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "basic_computing_power_accumulation_",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "total_amount_of_withdrawn_coins_",
          "type": "uint256"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_address",
          "type": "address"
        }
      ],
      "name": "GetUserMiningStatisticsData",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "_total_destroyed_quantity",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_total_amount_of_withdrawn_coins",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_address",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "page",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "num",
          "type": "uint256"
        }
      ],
      "name": "GetUserDestructionList",
      "outputs": [
        {
          "internalType": "uint256[]",
          "name": "start_time_",
          "type": "uint256[]"
        },
        {
          "internalType": "uint256[]",
          "name": "over_time_",
          "type": "uint256[]"
        },
        {
          "internalType": "uint256[]",
          "name": "basic_computing_power_accumulation_",
          "type": "uint256[]"
        },
        {
          "internalType": "uint256[]",
          "name": "total_amount_of_withdrawn_coins_",
          "type": "uint256[]"
        },
        {
          "internalType": "string[]",
          "name": "guid_arr",
          "type": "string[]"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "guid",
          "type": "string"
        },
        {
          "internalType": "uint256",
          "name": "_start_time",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_end_time",
          "type": "uint256"
        }
      ],
      "name": "SetTaskWorkTimeFromGUID",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_owner",
          "type": "address"
        }
      ],
      "name": "UpdateContractOwner",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bool",
          "name": "_can_destruction",
          "type": "bool"
        },
        {
          "internalType": "bool",
          "name": "_can_withdrawal",
          "type": "bool"
        }
      ],
      "name": "SetConfig",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "guid",
          "type": "string"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "Withdrawal",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "guid",
          "type": "string"
        }
      ],
      "name": "GetIncomeFromGUID",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "days_",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "start_time_",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "end_time_",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "one_day_income_",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "now_amount_",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "GetAllConfigData",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "_currentTotalSupply",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_historyTotalSupply",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_reserveTotalSupply",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_pledge_period_time",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_mining_rate",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_global_destroyed_quantity",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_maximum_destroyable_quantity",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_global_mining_volume",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_max_work_task",
          "type": "uint256"
        },
        {
          "internalType": "address",
          "name": "_myaddress",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_address",
          "type": "address"
        }
      ],
      "name": "GetMiningConsultation",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "_computational_power_start_time",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_total_remaining_output",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_already_total_withdrawal_amount",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_can_total_withdrawal_amount",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "_value",
          "type": "uint256"
        }
      ],
      "name": "transfer",
      "outputs": [
        {
          "internalType": "bool",
          "name": "success",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_from",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "_to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "_value",
          "type": "uint256"
        }
      ],
      "name": "transferFrom",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_spender",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "_value",
          "type": "uint256"
        }
      ],
      "name": "approve",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_owner",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "_spender",
          "type": "address"
        }
      ],
      "name": "allowance",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
  ];

  //#endregion
}