import Config from "../Config";
import TronContract from "./TronContract";
import TronTRX from "./TronTRX";
import TronWorker from "@/script/Tron/Worker/tron.worker.ts";
import { TronTaskMessageVO, DefaultColorEnum } from "./Global/GlobalType";
import TronTools from "./TronTools";
const TronWeb = require("tronweb");
export default class TronBase {
    protected _tronWeb: any = null;
    public trx: TronTRX;
    public contract: TronContract;
    /**
     * 工具类
     */
    public tools: TronTools;

    /**
     * 后台任务工作服务TaskWorker
     */
    // private task_worker: Worker;
    constructor() {
        const HttpProvider = TronWeb.providers.HttpProvider;
        const fullNode = new HttpProvider(Config.fullNodeURL);//节点服务
        const solidityNode = new HttpProvider(Config.solidityNodeURL);//合约服务
        const eventServer = new HttpProvider(Config.eventServerURL); //事件服务
        this._tronWeb = new TronWeb(fullNode, solidityNode, eventServer);
        this.trx = new TronTRX(this);
        this.contract = new TronContract(this);
        this.tools = new TronTools(this);
        // this.task_worker = new TronWorker();

        // this.task_worker.onmessage = (e) => {
        //     
        // };

        // this.task_worker.onmessageerror = (e) => {
        //     
        // };

        // this.task_worker.onerror = (e) => {
        //     
        //     "Worker断开连接".ShowLog(DefaultColorEnum["#19CAAD"]);
        // };
    }

    /**
     * 添加一个任务
     */
    // public AddTaskWork(task: TronTaskMessageVO) {
    //     this.task_worker.postMessage(task);
    // }

    public GetTronWeb() {
        return this._tronWeb;
    }


    /**
     * 设置私钥
     * @param privateKey 
     */
    public SetPrivateKey(privateKey: string) {
        this._tronWeb.setPrivateKey(privateKey);
    }

    /**
     * 获取默认私钥
     */
    public GetDefaultPrivateKey() {
        return this._tronWeb.defaultPrivateKey;
    }

    /**
  * 设置用户地址
  * @param address 
  * @returns 
  */
    protected SetAddress(address: string): boolean {
        try {
            this._tronWeb.setAddress(address);
            return true;
        }
        catch (e) {

        }
        return false;
    }

    /**
     * 获取默认地址
     * @returns 
     */
    public GetDefaultAddress(): {
        hex: string,
        base58: string
    } {
        return this._tronWeb.defaultAddress;
    }



    /**
     * 检查连接是否断开
     * @returns 
     */
    public async IsConnectedAsync(): Promise<{
        fullNode: boolean,
        solidityNode: boolean,
        eventServer: boolean
    }> {
        return await this._tronWeb.isConnected();
    }


    /**
     * 是否为地址
     * @param address 
     */
    public IsAddress(address: string) {
        return this._tronWeb.isAddress(address);
    }
}