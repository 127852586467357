<template>
  <div :class="['alert_body', alert_type == 'success' ? 'success' : 'error']">
    {{ message }}
  </div>
</template>
<script>
export default {
  props: {
    message: String,
    alert_type: String,
  },
  methods: {},
  mounted() {
    setTimeout(() => {
      this.$parent.show_alert = false;
    }, 3000);
  },
};
</script>
<style scoped>
.alert_body {
  position: fixed;
  top: 0px;
  /* height: 50px; */
  padding: 20px 5px;
  width: 100%;
  z-index: 1000;
  display: flex;
  align-content: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}
.success {
  background-color: rgb(50, 186, 137);
}
.error {
  background-color: brown;
}
</style>