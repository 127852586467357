import TronTools from "../Lib/GlobalTools";

export enum DefaultColorEnum {
    "#19CAAD",
    "#8CC7B5",
    "#A0EEE1",
    "#BEE7E9",
    "#BEEDC7",
    "#D6D5B7",
    "#D1BA74",
    "#E6CEAC",
    "#ECAD9E",
    "#F4606C"
}

export class TronTransactionSendConfigVO {
    public feeLimit?: number = 100000000;
    public callValue?: number;
    public tokenId?: number;
    public tokenValue?: number;
    public shouldPollResponse?: boolean;
}

export enum WORK_MESSAGE_TYPE_ENUM {
    /**
     * 等待确认的交易
     */
    WaitConfirmedTransaction,
}

/**
 * 波场链上消息任务队列
 */
export class TronTaskMessageVO {
    public type: WORK_MESSAGE_TYPE_ENUM;
    public rand_number: string;
    public work_data: any;
    public callback?: (v: any) => void;
    constructor(type: WORK_MESSAGE_TYPE_ENUM, work_data: any, callback?: (v: any) => void) {
        this.type = type;
        this.work_data = work_data;
        this.rand_number = TronTools.GUID;
        this.callback = callback;
    }
};


export type SendTransactionTRXData = {
    result: boolean,
    transaction:
    {
        visible: boolean,
        txID: string,
        raw_data:
        {
            contract: any[],
            ref_block_bytes: string,
            ref_block_hash: string,
            expiration: number,
            timestamp: number
        },
        raw_data_hex: string,
        signature: string[]
    }
};