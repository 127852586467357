import Tools from "@/script/Tools";
import { DefaultColorEnum } from "@/script/Tron/Global/GlobalType";
String.prototype.IsNull = function () {
    return Tools.StrIsNull(this as any);
}
String.prototype.IsNotNull = function () {
    return Tools.StrIsNotNull(this as any);
}

/**
 * 弹出成功提示
 */
String.prototype.AlertSuccess = function (out_time: number = 3000) {
    return Tools.AlertSuccess(this as string, out_time);
}

/**
 * 弹出错误提示
 */
String.prototype.AlertError = function (out_time: number = 3000) {
    return Tools.AlertError(this as string, out_time);
}

String.prototype.ShowLog = function (color: DefaultColorEnum,tag?: string) {
    Tools.ShowLog(tag, this as any, DefaultColorEnum[color])
}