import { createApp, reactive } from 'vue'
import App from './App.vue'
import router from './router';
import type TronBase from './script/Tron/TronBase';
import "@/script/GlobalExt";
let app = createApp(App);

//#region 定义全局变量
let $G: {
    [key: string]: {
        Tron: TronBase
    }
} = app.config.globalProperties.$G = reactive({})

app.use(router);
app.mount('#app')
