import { Contract, ContractAbi } from "web3";
import Decimal from "decimal.js";
export interface EthTransferData {
    blockHash: string;
    blockNumber: BigInt,
    cumulativeGasUsed: BigInt,
    effectiveGasPrice: BigInt,
    from: string,
    gasUsed: BigInt,
    logs: {
        address: string,
        blockHash: string,
        blockNumber: BigInt,
        data: string,
        logIndex: BigInt,
        removed: boolean,
        transactionHash: string,
        transactionIndex: BigInt
    }[]
    logsBloom: string,
    status: BigInt
    to: string,
    transactionHash: string,
    transactionIndex: BigInt,
    type: BigInt
}
export default class EthContract {
    protected _contract: Contract<ContractAbi>;
    constructor(_contract: Contract<ContractAbi>) {
        this._contract = _contract;
    }
    /**
     * 调用合约
     * @param key 
     * @param props 
     * @returns 
     */
    public async CallAsync<T>(key: string, ...props: any[]): Promise<T> {
        try {
            return await (this._contract.methods[key] as any)(...props).call();
        }
        catch (e) {
            console.error(`出现异常:${key}`);
            throw e;
        }
    }

    /**
     * 调用合约
     * @param key 
     * @param sendProps Send用参数
     * @param props 函数参数
     * @returns 
     */
    public async SendAsync<T>(key: string, sendProps: any, ...props: any[]): Promise<EthTransferData> {
        return (this._contract.methods[key] as any)(...props).send(sendProps) as EthTransferData;
    }
}