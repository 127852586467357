<template>
  <Alert v-if="show_alert" :alert_type="alert_type" :message="alert_message"></Alert>
  <LoadingComponent
    v-if="show_loading"
    :loading_message="loading_message"
  ></LoadingComponent>
  <router-view />
</template>

<script>
import LoadingComponent from "@/components/LoadingComponent.vue";
import Global from "./script/Global";
import Tron from "./script/Tron/Tron";
import Config from "./script/Config";
import MetaMask from "./script/Eth/MetaMask";
import Tools from "./script/Tools";
import Alert from "@/components/Alert.vue";
export default {
  data() {
    return {
      show_alert: false,
      alert_type: "",
      alert_message: "",
      loading_message: "",
      show_loading: false,
    };
  },
  components: {
    LoadingComponent,
    Alert,
  },
  methods: {
    Alert(message, type) {
      this.alert_message = message;
      this.alert_type = type;
      this.show_alert = true;
    },
    /**
     * 显示Loading
     * @param message
     */
    ShowLoading(message) {
      this.loading_message = message;
      this.show_loading = true;
    },
    /**
     * 关闭Loading
     */
    HideLoading() {
      this.show_loading = false;
    },
  },
  created() {
    Global.app = this;
    if (!MetaMask.is_init) {
      Tools.GoTo("/");
    }
  },
};
</script>

<style lang="scss">
html,
body {
  background-color: rgb(42, 47, 66);
  text-align: center;
  color: white;
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
}
</style>
