import { Component, GlobalComponents } from "vue";
import Tools from "./Tools";
import Tron from "./Tron/Tron";
import TronBase from "./Tron/TronBase";

export default class Global {
    /**
     * APP
     */
    public static app?: {
        $router: any,
        /**
         * 显示Loading
         * @param message 
         */
        ShowLoading(message: string): void;
        /**
         * 弹出框
         * @param message 
         * @param type 
         */
        Alert(message: string, type: "success" | "error"): void;
        /**
         * 关闭Loading
         */
        HideLoading(): void;
    };

    /**
     * 波场区块链对象
     */
    public static Tron: TronBase;

    /**
     * 最后的参数
     */
    public static params_data_dic: { [url: string]: any } = {};

    /**
     * 获取USDT货币价格
     * @returns 
     */
    public static async GetUSDTAmountAsync(): Promise<{
        usd: number,
        cny: number
    }> {
        let response = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=tether&vs_currencies=usd,cny");
        let v = await response.json();
        let d: any = {};
        for (let _key in v) {
            d = v[_key];
        }
        return d;
    }
}